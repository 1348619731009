<template>
    <div class="card-carousel">
            <div class="card-carousel__image"><img :src="'http://dev.npo-tlk.ru' + img" alt="card"></div>
            <div class="card-carousel__desc">{{title}}</div>
    </div>
</template>

<style lang="scss" scoped>
    .card-carousel{
        background-color: #505050;
        padding-bottom: 34px;
        cursor: pointer;
        &__image{
            width: 100%;
            img{
                width: 100%;
            }
        }
        &__desc{
            font-family: 'Roboto';
            font-size: 18px;
            font-weight: 400;
            line-height: 24px;
            color: #f5f5f5;
            padding: 0 40px;
            margin-top: 38px;
        }
    }
</style>

<script>

export default {
  name: 'CardCarousel',
  props: ['id', 'img', 'title']
}
</script>
