<template>
    <footer>
        <div class="main-footer">
            <div class="main-footer__section">
                <div class="main-footer__section__title" @click="changeFooter">Продукция и услуги</div>
                <div class="main-footer__section__elems">
                    <a  class="main-footer__section__elems__el">Каталоги решений</a>
                    <a class="main-footer__section__elems__el">Сделано на заказ</a>
                    <a class="main-footer__section__elems__el">Разработка и проектирование</a>
                    <a class="main-footer__section__elems__el">Технические консультации</a>
                    <a class="main-footer__section__elems__el">Где купить?</a>
                </div>
            </div>
            <div class="main-footer__section">
                <div class="main-footer__section__title" @click="changeFooter">Новости</div>
                <div class="main-footer__section__elems">
                    <a class="main-footer__section__elems__el">Все новости</a>
                    <a class="main-footer__section__elems__el">Компания</a>
                    <a class="main-footer__section__elems__el">Производство</a>
                    <a class="main-footer__section__elems__el">Продукция</a>
                    <a class="main-footer__section__elems__el">Мероприятия</a>
                </div>
            </div>
            <div class="main-footer__section">
                <div class="main-footer__section__title" @click="changeFooter">НПО ТЛК</div>
                <div class="main-footer__section__elems">
                    <a class="main-footer__section__elems__el">О компании</a>
                    <a class="main-footer__section__elems__el">Партнерам</a>
                    <a class="main-footer__section__elems__el">Поставщикам</a>
                    <a class="main-footer__section__elems__el">Вакансии</a>
                    <a class="main-footer__section__elems__el">Контакты</a>
                </div>
            </div>
            <div class="main-footer__section">
                <div class="main-footer__section__title" @click="changeFooter">Документы и манифесты</div>
                <div class="main-footer__section__elems">
                    <a class="main-footer__section__elems__el">Наш подход</a>
                    <a class="main-footer__section__elems__el">ТОР Гаврилов ЯМ</a>
                    <a class="main-footer__section__elems__el">Социальная ответственность</a>
                    <a class="main-footer__section__elems__el">Об охране труда</a>
                    <a class="main-footer__section__elems__el">Документы</a>
                </div>
            </div>
        </div>
        <div class="bottom-footer">
            <div class="bottom-footer__mobile-info">
                <a href="tel:+74852388040" class="bottom-footer__mobile-info__phone">+7 4852 38-80-40</a>
                <a href="mailto:info@npo-tlk.ru" class="bottom-footer__mobile-info__email">info@npo-tlk.ru</a>
            </div>
            <div class="bottom-footer__navigation">
                <a class="bottom-footer__navigation__elem">Copyright @ НПО "ТЛК" 2021</a>
                <a class="bottom-footer__navigation__elem">Карта сайта</a>
                <a class="bottom-footer__navigation__elem">Политика конфиденциальности</a>
            </div>
            <div class="bottom-footer__social">
                <SocialBlock/>
            </div>
        </div>
    </footer>
</template>

<style lang="scss" scoped>
    footer{
        background-color: #323232;
        padding: 33px 0 24px 0;
    }
    .main-footer{
        display: flex;
        &__section{
            width: 25%;
            padding: 0 40px;
            &__title{
                font-family: 'Roboto';
                font-size: 18px;
                font-weight: 500;
                line-height: 24px;
                color: #fff;
            }
            &__elems{
                &__el{
                    margin-top: 21px;
                    font-family: 'Roboto';
                    font-size: 16px;
                    font-weight: 400;
                    line-height: 19px;
                    color: #c8c8c8;
                    display: block;
                    cursor: pointer;
                    &:not(:first-of-type){
                        margin-top: 13px;
                    }
                }
            }
        }
    }
    @media screen and (max-width: 960px) {
        .main-footer{
            flex-direction: column;
            align-items: center;
            &__section{
                width: 100%;
                border-bottom: 1px solid #c8c8c8;
                &:not(:first-of-type){
                    margin-top: 22px;
                }
                padding-bottom: 26px;
                &__elems{
                    transition: 0.4s;
                    max-height: 0;
                    overflow: hidden;
                }
                &__title{
                    cursor: pointer;
                    display: flex;
                    justify-content: space-between;
                    &::after{
                        content: url('../../../assets/core/arrow-footer.svg');
                        transition-duration: .4s;
                    }
                }
            }
            &__section.active .main-footer__section__title::after{
                transform: rotateZ(-180deg);
            }
            &__section.active .main-footer__section__elems{
                max-height: 200px;
            }
        }
    }
    .bottom-footer{
        padding: 50px 0 0 0;
        display: flex;
        align-items: center;
        &__mobile-info{
            display: none;
        }
        &__navigation{
            display: flex;
            padding-left: 40px;
            height: 16px;
            width: 75%;
            &__elem{
                font-family: 'Roboto';
                font-size: 12px;
                font-weight: 400;
                line-height: 14px;
                color: #c8c8c8;
                cursor: pointer;
                &:first-of-type{
                    padding-left: 0;
                }
                padding: 0 16px;
                &:nth-child(2){
                    border-left: 1px solid #c8c8c8;
                    border-right: 1px solid #c8c8c8;
                }
            }
        }
    }
    @media screen and (max-width: 960px) {
        .bottom-footer{
            flex-direction: column;
            display: flex;
            &__social{
                display: none;
            }
            &__mobile-info{
                display: flex;
                flex-direction: column;
                width: 100%;
                align-items: center;
                a{
                    font-family: 'Roboto';
                    font-size: 18px;
                    font-weight: 500;
                    line-height: 24px;
                    color: #c8c8c8;
                    &:not(:first-of-type){
                        margin-top: 24px;
                        text-decoration: underline;
                    }
                }
            }
            &__navigation{
                margin-top: 26px;
                background-color: #323232;
                width: 100%;
                height: auto;
                align-items: center;
                flex-direction: column;
                padding: 0;
                &__elem{
                    padding: 0;
                    &:nth-child(2){
                        border-left: none;
                        border-right: none;
                    }
                    margin-top: 16px;
                }
            }
        }
    }
</style>

<script>
import SocialBlock from '@/components/helpers/footer/SocialBlock.vue'

export default {
  name: 'Footer',
  components: {
    SocialBlock
  },
  methods: {
    changeFooter (e) {
      const el = e.target.parentElement
      el.classList.toggle('active')
    }
  }
}
</script>
