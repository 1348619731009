var render = function render(){var _vm=this,_c=_vm._self._c;return _c('footer',[_c('div',{staticClass:"main-footer"},[_c('div',{staticClass:"main-footer__section"},[_c('div',{staticClass:"main-footer__section__title",on:{"click":_vm.changeFooter}},[_vm._v("Продукция и услуги")]),_vm._m(0)]),_c('div',{staticClass:"main-footer__section"},[_c('div',{staticClass:"main-footer__section__title",on:{"click":_vm.changeFooter}},[_vm._v("Новости")]),_vm._m(1)]),_c('div',{staticClass:"main-footer__section"},[_c('div',{staticClass:"main-footer__section__title",on:{"click":_vm.changeFooter}},[_vm._v("НПО ТЛК")]),_vm._m(2)]),_c('div',{staticClass:"main-footer__section"},[_c('div',{staticClass:"main-footer__section__title",on:{"click":_vm.changeFooter}},[_vm._v("Документы и манифесты")]),_vm._m(3)])]),_c('div',{staticClass:"bottom-footer"},[_vm._m(4),_vm._m(5),_c('div',{staticClass:"bottom-footer__social"},[_c('SocialBlock')],1)])])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"main-footer__section__elems"},[_c('a',{staticClass:"main-footer__section__elems__el"},[_vm._v("Каталоги решений")]),_c('a',{staticClass:"main-footer__section__elems__el"},[_vm._v("Сделано на заказ")]),_c('a',{staticClass:"main-footer__section__elems__el"},[_vm._v("Разработка и проектирование")]),_c('a',{staticClass:"main-footer__section__elems__el"},[_vm._v("Технические консультации")]),_c('a',{staticClass:"main-footer__section__elems__el"},[_vm._v("Где купить?")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"main-footer__section__elems"},[_c('a',{staticClass:"main-footer__section__elems__el"},[_vm._v("Все новости")]),_c('a',{staticClass:"main-footer__section__elems__el"},[_vm._v("Компания")]),_c('a',{staticClass:"main-footer__section__elems__el"},[_vm._v("Производство")]),_c('a',{staticClass:"main-footer__section__elems__el"},[_vm._v("Продукция")]),_c('a',{staticClass:"main-footer__section__elems__el"},[_vm._v("Мероприятия")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"main-footer__section__elems"},[_c('a',{staticClass:"main-footer__section__elems__el"},[_vm._v("О компании")]),_c('a',{staticClass:"main-footer__section__elems__el"},[_vm._v("Партнерам")]),_c('a',{staticClass:"main-footer__section__elems__el"},[_vm._v("Поставщикам")]),_c('a',{staticClass:"main-footer__section__elems__el"},[_vm._v("Вакансии")]),_c('a',{staticClass:"main-footer__section__elems__el"},[_vm._v("Контакты")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"main-footer__section__elems"},[_c('a',{staticClass:"main-footer__section__elems__el"},[_vm._v("Наш подход")]),_c('a',{staticClass:"main-footer__section__elems__el"},[_vm._v("ТОР Гаврилов ЯМ")]),_c('a',{staticClass:"main-footer__section__elems__el"},[_vm._v("Социальная ответственность")]),_c('a',{staticClass:"main-footer__section__elems__el"},[_vm._v("Об охране труда")]),_c('a',{staticClass:"main-footer__section__elems__el"},[_vm._v("Документы")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"bottom-footer__mobile-info"},[_c('a',{staticClass:"bottom-footer__mobile-info__phone",attrs:{"href":"tel:+74852388040"}},[_vm._v("+7 4852 38-80-40")]),_c('a',{staticClass:"bottom-footer__mobile-info__email",attrs:{"href":"mailto:info@npo-tlk.ru"}},[_vm._v("info@npo-tlk.ru")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"bottom-footer__navigation"},[_c('a',{staticClass:"bottom-footer__navigation__elem"},[_vm._v("Copyright @ НПО \"ТЛК\" 2021")]),_c('a',{staticClass:"bottom-footer__navigation__elem"},[_vm._v("Карта сайта")]),_c('a',{staticClass:"bottom-footer__navigation__elem"},[_vm._v("Политика конфиденциальности")])])
}]

export { render, staticRenderFns }