<template>
    <div class="post">
        <BannerHome 
            title='О компании НПО ТЛК'
            img='image8.png'
        />
        <div class="post__text">
            НПО ТЛК — молодое но амбициозное предприятие, созданное как независимая площадка по производству изделий из листового металла. 
            Прежде всего — высококачественной и современной продукции в области телекоммуникаций. 
            Мы производим серверные шкафы, стойки, кроссы, различные монтажные конструктивы и многое другое. 
            <br>
            Возможна кастомизация изделий под заказчика или изготовление по его чертежам.
            <br>
            Наши инженеры помогу реализовать любую вашу идею. 
            <br>
            <br>
            Наша цель — производство изделий, способных конкурировать на российском рынке с западными импортными аналогами как по цене, так и по функциональным характеристикам. 
            Для осуществления данной стратегии предполагается высокая автоматизация производства и только новое и современное оборудование. 
            <br>
            <br>
            Использование современного оборудования и комплексной системы автоматизации, в сравнении с существующими предприятиями отрасли, позволяет нам повысить производительность труда и уровень культуры производства на предприятии, снизить себестоимость изделий, уменьшить процент внутреннего брака и значительно сэкономить используемые энергоресурсы. 
            А современное оборудование (преимущественно - немецкого и японского производства), позволит длительное время применить передовые технологии без необходимости модернизации технической базы.
            <br>
            <br>
            ТОСЭР или ТОР (территория опережающего социально-экономического развития) — экономическая зона со льготными налоговыми условиями, упрощёнными административными процедурами и другими привилегиями в России, создаваемая для привлечения инвестиций, 
            ускоренного развития экономики и улучшения жизни населения. От чего в конечном счете выигрывают все.
            <br>
            <br>
            НПО ТЛК не просто является участником программы ТОСЭР в городе Гаврилов-Ям, мы приложили большие усилия, чтобы сам город получил такой статус. 
            <br>
            <br>
            Трудоустройство и обучение местного населения - один из наших приоритетов. 
            На данный момент более 80% сотрудников компании - это жители Гаврилов-Яма. 
            Мы заинтересованы в экономическом развитии района и видим в нем большой потенциал. 
            Только вместе мы сможем добиться общего блага.
        </div>
    </div>
</template>
<style lang='scss' scoped>
.banner{
    margin-top: 32px;
}
.post{
    width: 100%;
    &__text{
        margin-top: 46px;
        font-family: 'Roboto';
        font-size: 18px;
        font-weight: 400;
        line-height: 24px;
        color: #323232;
        max-width: 720px;
        @media screen and (max-width: 960px){
            padding: 0 40px;
        }

    }
    &__date{
        font-family: 'Roboto';
        font-size: 14px;
        font-weight: 700;
        line-height: 16px;
        color: #323232;
    }
    &__header{
        display: flex;
        justify-content: space-between;
        &__title{
            font-family: 'Roboto';
            font-size: 24px;
            font-weight: 500;
            line-height: 32px;
            color: #505050;
            max-width: 722px;
        }
        &__arrows{
            margin: 0 auto;
            text-align: center;
            a{
                display: block;
                font-family: 'Roboto';
                font-size: 14px;
                font-weight: 400;
                line-height: 24px;
                color: #323232;
            }
            &__prev::before{
                content: url('../../../assets/core/arrow-prev-pages.svg');
                margin-right: 8px;
                height: 26px;
            }
            &__next::after{
                content: url('../../../assets/core/arrow-next-pages.svg');
                margin: 8px 0 0 8px;
                height: 26px;
            }
        }
    }
}
</style>

<script>
import BannerHome from '@/components/helpers/banners/BannerHome.vue'

export default {
  name: 'StandartTemplate',
  components: {
    BannerHome
  }
}
</script>
