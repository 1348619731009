<template>
    <div class="card" :style="{ backgroundImage: 'url(' + (getImageUrl()) + ')' }">
        <p class="card__title">{{title}}</p>
        <a :href="url" class="card__pop-up-text">
            <div class="card__pop-up-text__title">{{title}}</div>
            <div class="card__pop-up-text__description">{{desc}}</div>
            <div class="card__pop-up-text__link" v-if="bottomText">{{bottomText}}</div>
        </a>
    </div>
</template>

<style lang="scss" scoped>
   .card{
        background-position: center;
        background-repeat: no-repeat;
        background-size: cover;
        overflow: hidden;
        cursor: pointer;
        position: relative;
        &:hover{
            .card__pop-up-text{
                opacity: 1;
            }
            .card__title{
                opacity: 0;
            }
        }
        &__title{
            padding: 36px 40px 40px 40px;
            font-family: 'Roboto';
            transition-duration: .5s;
            font-size: 24px;
            font-weight: 500;
            position: relative;
            line-height: 24px;
            color: #fff;
            text-transform: uppercase;
        }
        &__pop-up-text{
            opacity: 0;
            transition-duration: .5s;
            position: absolute;
            width: 100%;
            z-index: 1;
            height: 100%;
            background-color:rgba(0, 0, 0, 0.5);
            top: 0;
            left: 0;
            &__title{
                padding: 36px 40px 0 40px;
                font-family: 'Roboto';
                font-size: 24px;
                font-weight: 500;
                position: relative;
                line-height: 24px;
                color: #fff;
                text-transform: uppercase;
            }
            &__description, &__link{
                font-family: 'Roboto';
                font-size: 18px;
                font-style: normal;
                font-weight: 400;
                line-height: 24px;
                color: #fff;
            }
            &__description{
                padding: 0 40px;
                margin-top: 34px;
                bottom: 36px;
                left: 40px;
                @media screen and (max-width:480px){
                    font-size: 16px;
                    padding: 0 30px 0 40px;
                }
            }
            &__link{
                position: absolute;
                bottom: 36px;
                left: 40px;
            }
        }
   }
</style>

<script>

export default {
  name: 'CardStandart',
  methods: {
    getImageUrl () {
      return require(`../../../assets/banners/${this.img}`)
    }
  },
  props: {
    title: String,
    desc: String,
    url: String,
    bottomText: String,
    img: String

  }
}
</script>
