<template>
    <button @click="goToUrl()">{{text}}</button>
</template>

<style lang="scss" scoped>
    button{
        background-color: #b75106;
        padding: 0 27px;
        height: 48px;
        display: flex;
        align-items: center;
        justify-content: center;
        color: #fff;
        outline: none;
        font-family: 'Roboto';
        font-size: 16px;
        text-transform: uppercase;
        cursor: pointer;
        font-weight: 500;
        transition-duration: .3s;
        line-height: 24px;
        border: none;

        &:hover{
            background-color: #EF7D05;
        }

        &:active{
             background-color: #505050;
             transition-duration: 0s;
        }

        &:disabled{
            background-color: #f5f5f5;
            color: #b4b4b4;
        }
    }
</style>

<script>
export default {
  name: 'Button',
  props:['text', 'url'],
  methods: {
      goToUrl(){
          document.location = this.url
      }
  }
}
</script>
