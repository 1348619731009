<template>
    <div class="left-menu">
        <!--<div class="left-menu__item">
            <div class="left-menu__item__date">18.05.2021</div>
            <div class="left-menu__item__text">НПО ТЛК запускает в производство обновленную линейку настенных шкафов TLK TWC с опциональной поворотной рамой TLK-BS</div>
        </div>-->
        <slot></slot>
    </div>
</template>

<style lang="scss" scoped>
    .left-menu{
        padding: 0 40px 54px 40px;
        .router-link-active{
            color: #b75106;
            position: relative;
            &:before{
                content: url('../../../assets/core/arrow-menu.svg');
                position: absolute;
                left: -18px;
            }
        }
        &__item-menu{
            display: block;
            width: 280px;
            @media screen and (max-width: 480px) {
                width: auto;
            }
            font-family: 'Roboto';
            font-size: 18px;
            font-weight: 400;
            line-height: 24px;
            color: #505050;
            transition-duration: .4s;
            &:hover{
               color: #b75106;
            }
            &:not(:first-of-type){
                margin-top: 32px;
            }
        }
        &__item{
            max-width: 280px;
            &:not(:first-of-type){
                margin-top: 54px;
            }
            &__date{
                font-family: 'Roboto';
                font-size: 14px;
                font-weight: 700;
                line-height: 16px;
                color: #323232
            }
            &__text{
                margin-top: 27px;
                font-family: 'Roboto';
                font-size: 18px;
                font-weight: 400;
                line-height: 24px;
                color: #505050;
            }
        }
    }
</style>

<script>
export default {
  name: 'LeftMenu'
}
</script>
