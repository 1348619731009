<template>
    <div class="card-news">
        <div class="card-news__title">Новости</div>
        <div class="card-news__items">
            <div 
                class="card-news__items__item" 
                v-for="item in pubs"
                :key="item.id"
            >
                <div class="card-news__items__item__date">{{convertDate(item.publish)}}</div>
                <div class="card-news__items__item__text">{{item.title}}</div>
            </div>
        </div>
        <a class="card-news__show-more">Все новости</a>
    </div>
</template>

<style lang="scss" scoped>
   .card-news{
       position: relative;
       padding: 36px 40px 90px 40px;
       &__title{
            font-family: 'Roboto';
            font-size: 24px;
            font-weight: 500;
            line-height: 24px;
            color: #000;
            text-transform: uppercase;
       }
       &__items{
            display: flex;
            flex-direction: column;
            align-items: center;
            margin-top: 39px;
            @media screen and (max-width: 1440px) {
                flex-direction: row;
                flex-wrap: wrap;
                justify-content: space-between;
                align-items: flex-start;
            }
            &__item{
                width: 100%;
                @media screen and (max-width: 1440px) {
                    width: calc(50% - 40px);
                    margin-top: 37px;
                }
                &:not(:first-of-type){
                    margin-top: 37px;
                }
                &__date{
                    font-family: 'Roboto';
                    font-size: 14px;
                    font-weight: 700;
                    line-height: 24px;
                    color: #323232;
                }
                &__text{
                    font-family: 'Roboto';
                    font-size: 18px;
                    font-weight: 400;
                    line-height: 24px;
                    color: #505050;
                    margin-top: 11px;
                }
                @media screen and (max-width: 640px) {
                    width: 100%;
                    margin-top: 37px;
                }
            }
       }
       &__show-more{
            position: absolute;
            bottom: 33px;
            left: 40px;
            font-family: 'Roboto';
            font-size: 16px;
            font-weight: 400;
            line-height: 24px;
            color: #323232;
            text-transform: uppercase;
            cursor: pointer;
            display: none; //   uncomment in the future
       }
   }
</style>

<script>
import axios from 'axios'

export default {
  name: 'CardNews',
  data: function () {
    return {
      pubs: []
    }
  },
  methods:{
    getPubs(){
        axios.get(`/api/publications/`).then(response => {
            this.pubs.push(...response.data)
            this.pubs = this.pubs.slice(0,4)
        })
    },
    convertDate(date){
        return date ? new Date(date).toLocaleDateString() : ""
    }
  },
  mounted () {
    this.getPubs()
  }
}
</script>
