<template>
    <div class="carousel-block">
        <div class="title">Продукция</div>
        <VueSlickCarousel v-bind="settings">
            <slot></slot>
        </VueSlickCarousel>
    </div>
</template>

<style lang="scss" scoped>
    @import "../../../../node_modules/vue-slick-carousel/dist/vue-slick-carousel.css";
    @import '../../../../node_modules/vue-slick-carousel/dist/vue-slick-carousel-theme.css';
    .carousel-block{
        background-color: #505050;
        padding-top: 36px;
    }
    .title{
        font-family: 'Roboto';
        font-size: 24px;
        font-weight: 500;
        line-height: 24px;
        color: #fff;
        text-transform: uppercase;
        margin-left: 40px;
    }
    /deep/ .slick-arrow{
            width: 10px;
            height: 20px;
            position: absolute;
            top: 50%;
            z-index: 10;
            transition-duration: .5s;
    }
    /deep/ .slick-next{
        right: 40px;

        &:before{
            content: url('../../../assets/core/arrow-next-carousel.svg');
        }
    }

    /deep/ .slick-prev{
        left: 40px;

        &:before{
            content: url('../../../assets/core/arrow-prev-carousel.svg');
        }
    }
</style>

<script>
import VueSlickCarousel from 'vue-slick-carousel'

export default {
  name: 'Carousel',
  components: {
    VueSlickCarousel
  },
  data: () => ({
    settings: [
      {
        dots: false,
        infinite: false,
        slidesToShow: 4,
        slidesToScroll: 1,
        arrow: true,
        focusOnSelect: false,
        accessibility: false,
        edgeFriction: 0.35,
        responsive: [
          {
            breakpoint: 960,
            settings: {
              slidesToShow: 3,
              slidesToScroll: 1
            }
          },
          {
            breakpoint: 640,
            settings: {
              slidesToShow: 2,
              slidesToScroll: 1
            }
          },
          {
            breakpoint: 480,
            settings: {
              slidesToShow: 1,
              slidesToScroll: 1
            }
          }
        ]
      }
    ]
  })
}
</script>
