<template>
    <div class="main">
        <Header/>
        <div class="content">
          <LeftMenu>
            <router-link class="left-menu__item-menu" exact to="/about">О компании</router-link>
            <router-link class="left-menu__item-menu" exact to="/about/contact">Контакты</router-link>
          </LeftMenu>
          <div class="content__main">
            <ContactTemplate/>
            <iframe src="https://yandex.ru/map-widget/v1/?um=constructor%3A19fead782971590276f5b71c18b90c7d940d4381601340bc4bdd128ddb7080fd&amp;source=constructor" width="100%" height="450" frameborder="0"></iframe>
          </div>
        </div>
        <DummyFooter/>
    </div>
</template>
<style lang='scss' scoped>
  .main {
    min-height: 100vh;
    display: grid;
    grid-template-rows: auto 1fr auto;
  }
  .content__main{
    width: 100%;
  }
  .content{
    display: flex;
    margin-top: 46px;
    @media screen and (max-width: 960px) {
      flex-direction: column;
    }
  }
  .grid-block-benefit{
        @media screen and (max-width: 960px) {
            height: auto;
            grid-template-columns: 1fr;
            grid-template-rows: repeat(3, minmax(480px, 1fr));
        }
        display: grid;
        height: 336px;
        width: 100%;
        grid-template-columns: 1fr 1fr 1fr;
    }
</style>

<script>
import Header from '@/components/helpers/header/Header.vue'
import ContactTemplate from '@/components/helpers/standart/ContactTemplate.vue'
import DummyFooter from '@/components/helpers/footer/DummyFooter.vue'
import SubMenu from '@/components/helpers/header/SubMenu.vue'
import LeftMenu from '@/components/helpers/standart/LeftMenu.vue'
import ContactForm from '@/components/helpers/forms/ContactForm.vue'

export default {
  name: 'ContactPage',
  components: {
    Header,
    DummyFooter,
    ContactTemplate,
    SubMenu,
    LeftMenu,
    ContactForm
  }
}
</script>
