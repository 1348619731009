<template>
    <div class="social-block">
        <a class="social-block__facebook"></a>
        <a class="social-block__inc"></a>
        <a class="social-block__instagram"></a>
        <a class="social-block__youtube"></a>
        <a class="social-block__twitter"></a>
    </div>
</template>

<style lang="scss" scoped>
    .social-block{
        display: flex;
        padding-left: 40px;
        & a{
            cursor: pointer;
        }
        & a:not(:last-of-type){
            margin-right: 8px;
        }

        &__facebook:before{
            content: url('../../../assets/core/facebook.svg');
            width: 40px;
            height: 40px;
        }
        &__inc:before{
            content: url('../../../assets/core/inc.svg');
            width: 40px;
            height: 40px;
        }
        &__instagram:before{
            content: url('../../../assets/core/instagram.svg');
            width: 40px;
            height: 40px;
        }
        &__youtube:before{
            content: url('../../../assets/core/youtube.svg');
            width: 40px;
            height: 40px;
            }
        &__twitter:before{
            content: url('../../../assets/core/twitter.svg');
            width: 40px;
            height: 40px;
        }
    }
</style>

<script>

export default {
  name: 'SocialBlock'
}
</script>
