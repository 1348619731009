<template>
    <div class="main">
        <Header/>
        <div class="content">
          <LeftMenu>
            <router-link class="left-menu__item-menu" exact to="/about">О компании</router-link>
            <router-link class="left-menu__item-menu" exact to="/about/contact">Контакты</router-link>
          </LeftMenu>
          <StandartTemplate/>
        </div>
        <div class="grid-block-benefit">
          <CardStandart img='image19.png' title='Производство'></CardStandart>
          <CardStandart img='image20.png' title='Продукция'></CardStandart>
          <CardStandart img='image21.png' title='Под Заказ'></CardStandart>
        </div>
        <DummyFooter/>
    </div>
</template>
<style lang='scss' scoped>
  .content{
    display: flex;
    margin-top: 46px;
    padding-bottom: 50px;
    @media screen and (max-width: 960px) {
      flex-direction: column;
    }
  }
  .grid-block-benefit{
        @media screen and (max-width: 960px) {
            grid-template-columns: 1fr;
            grid-template-rows: repeat(3, minmax(336px, auto));
        }
        @media screen and (max-width: 640px) {
            grid-template-rows: repeat(3, minmax(252px, auto));
        }
        display: grid;
        height: 336px;
        grid-template-rows: minmax(336px, auto);
        grid-template-columns: 1fr 1fr 1fr;
    }
</style>

<script>
import Header from '@/components/helpers/header/Header.vue'
import StandartTemplate from '@/components/helpers/standart/StandartTemplate.vue'
import DummyFooter from '@/components/helpers/footer/DummyFooter.vue'
import SubMenu from '@/components/helpers/header/SubMenu.vue'
import LeftMenu from '@/components/helpers/standart/LeftMenu.vue'
import CardStandart from '@/components/helpers/cards/CardStandart.vue'

export default {
  name: 'AboutPage',
  components: {
    Header,
    DummyFooter,
    StandartTemplate,
    SubMenu,
    LeftMenu,
    CardStandart
  }
}
</script>
