<template>
    <div 
        :class="['home-banner', {'center-text': centerText}]"
        :style="{ backgroundImage: 'url(' + (getImageUrl()) + ')' }"
    >
        <div class="home-banner__text">
            <div class="home-banner__text__title">
                {{title}}
            </div>
            <div class="home-banner__text__desc">
                {{desc}}
            </div>
            <div class="home-banner__text__button" v-if="url ">
                <Button :url='url' :text="textButton"/>
            </div>
        </div>
    </div>
</template>

<style lang="scss" scoped>
    .home-banner{
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    height: 400px;
    &.center-text{
        display: flex;
        justify-content: center;
        padding: 0 20px;
        .home-banner__text{
            align-items: center;
            max-width: 600px;
            padding-left: 0;
            .home-banner__text__desc{
                text-align: center;
                margin: 30px auto 0 auto;
            }
        }
    }
    &__text{
        height: 100%;
        padding-left: 40px;
        color: #fff;
        max-width: 385px;
        display: flex;
        flex-direction: column;
        justify-content: center;
        @media screen and (max-width: 640px) {
            text-align: center;
            margin: 0 auto;
            padding-left: 0;
            align-items: center;
        }
        &__title{
            font-family: 'Roboto';
            font-size: 40px;
            font-weight: 900;
            line-height: 48px;
            text-transform: uppercase;
        }
        &__desc{
            font-family: 'Roboto';
            font-size: 24px;
            font-weight: 500;
            line-height: 32px;
            margin-top: 24px;
            @media screen and (max-width: 640px) {
                font-size: 18px;
                line-height: 24px;
            }
            @media screen and (max-width: 480px) {
                max-width: 265px;
            }
        }
        &__button{
            @media screen and (max-width: 640px) {
                margin: 48px auto 0 auto;
            }
            margin-top: 48px;
        }
    }
}
</style>

<script>
import Button from '@/components/helpers/button/Button.vue'

export default {
  name: 'BannerHome',
  components: {
    Button
  },
  methods: {
    getImageUrl () {
      return require(`../../../assets/banners/${this.img}`)
    }
  },
  props: {
    centerText: Boolean,
    title: String,
    desc: String,
    url: String,
    textButton: String,
    img: String
  }
}
</script>
