<template>
    <div class="contact">
        <div class="contact__title">Контакты</div>
        <div class="contact__info">
            <div class="contact__info__communication">
                <div class="contact__info__communication__phone-block">
                    <div class="contact__name">Тел./факс</div>
                    <a href="tel:+74852388040 " class="contact__value">+7 4852 38-80-40</a>
                </div>
                <div class="contact__info__communication__email-block">
                    <div class="contact__name">Электронная почта</div>
                    <a href="mailto:info@npo-tlk.ru" class="contact__value">info@npo-tlk.ru</a>
                </div>
            </div>
            <div class="contact__info__adress-block">
                <div class="contact__name">Адресс</div>
                <div class="contact__value">152240, Ярославская область, Гаврилов-Ямский район, город Гаврилов-Ям, улица Комарова, дом 1, пом/эт 1</div>
            </div>
        </div>
    </div>
</template>
<style lang="scss" scoped>
    .contact{
        width: 100%;
        @media screen and (max-width: 960px) {
             padding: 0 40px 64px 40px;
        }
        padding-bottom: 64px;
        &__title{
            font-family: 'Roboto';
            font-size: 40px;
            font-weight: 900;
            line-height: 48px;
            color: #505050;
        }
        &__info{
            margin-top: 44px;
            display: flex;
            @media screen and (max-width: 960px) {
                flex-direction: column;
            }
            &__communication{
                display: flex;
                flex-direction: column;
                justify-content: space-between;
                white-space: nowrap;
                &__email-block .contact__value{
                    text-decoration: underline;
                }
                &__email-block{
                    margin-top: 30px;
                }
                @media screen and (max-width: 960px) {
                    width: 100%;
                }
            }
            &__adress-block{
                max-width: 480px;
                margin-left: 168px;
                @media screen and (max-width: 960px) {
                    width: 100%;
                    margin: 30px 0 0 0;
                }
            }
        }
        &__name{
            font-family: 'Roboto';
            font-size: 18px;
            font-weight: 400;
            line-height: 24px;
            color: #323232;
        }
        &__value{
            margin-top: 10px;
            font-family: 'Roboto';
            font-size: 24px;
            font-weight: 500;
            line-height: 32px;
            color: #505050;
        }
        .contact__value{
            margin-top: 10px;
            display: block;
        }
    }
</style>

<script>

export default {
  name: 'ContactTemplate',
  components: {

  }
}
</script>
