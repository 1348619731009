import Vue from 'vue'
import VueRouter, { RouteConfig } from 'vue-router'
import HomePage from '../views/HomePage.vue'
import AboutPage from '../views/AboutPage.vue'
import CatalogPage from '../views/CatalogPage.vue'
import ContactPage from '../views/ContactPage.vue'
import InfoPages from '../views/InfoPages.vue'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'HomePage',
    component: HomePage
  },
  /*{
    path: '/catalog',
    name: 'CatalogPage',
    component: CatalogPage
  },*/
  {
    path: '/about',
    component: InfoPages,
    children: [
      {
        path: 'contact',
        name: 'ContactPage',
        component: ContactPage
      },
      {
        path: '',
        name: 'AboutPage',
        component: AboutPage
      }
    ]
  },
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

export default router
