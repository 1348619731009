<template>
    <footer>
        <div class="footer-copyright">
            Copyright @ НПО "ТЛК" {{ currentYear }}
        </div>
    </footer>
</template>

<style lang="scss" scoped>
    footer{
        background-color: #323232;
        padding: 30px 0 30px 40px;
        color: #C8C8C8;
        font-family: 'Roboto', sans-serif;
        font-size: 12px;
        font-weight: 400;
        line-height: 14px;
    }
</style>

<script>

export default {
  name: 'DummyFooter',
  data() {
    return {
      currentYear: new Date().getFullYear()
    }
  }
}
</script>
