<template>
    <div class="main">
        <Header/>
        <div class="catalog" v-if="products.length != 0">
            <CardCarousel
                v-for="(item, index) in products" 
                :key="index"
                :title="item.title"
                :img="item.icon"
                :id="item.id"
            ></CardCarousel>
        </div>
        <Footer/>
    </div>
</template>

<style lang="scss" scoped>
  .catalog{
    display: flex;
    margin-top: 43px;
    flex-wrap: wrap;
    background-color: #505050;
  }
  .card-carousel{
      width: 25%;
      @media screen and (max-width: 1440px) {
        width: 33%;
      }
      @media screen and (max-width: 960px) {
        width: 50%;
      }
      @media screen and (max-width: 480px) {
        width: 100%;
      }
  }
</style>

<script>
import Header from '@/components/helpers/header/Header.vue'
import Footer from '@/components/helpers/footer/Footer.vue'
import SubMenu from '@/components/helpers/header/SubMenu.vue'
import CardCarousel from '@/components/helpers/cards/CardCarousel.vue'
import axios from 'axios'

export default {
  name: 'CatalogPage',
  components: {
    Header,
    Footer,
    SubMenu,
    CardCarousel
  },
  data: function () {
    return {
      products: []
    }
  },
  mounted () {
    axios.get('http://dev.npo-tlk.ru/api/products/').then(response => {
      this.products.push(...response.data)
    })
  }
}
</script>
