<template>
    <div class="contact-form">
        <div class="contact-form__title">Быстрая заявка</div>
        <form>
            <div class="name elem">
                <label for="name">Имя</label>
                <input type="text" name="name" placeholder="Введите имя">
                <div class="error">Введите имя, допустиы кириллица, пробел и -</div>
            </div>
            <div class="surname elem">
                <label for="surname">Фамилия</label>
                <input type="text" name="surname" placeholder="Введите фамилию">
                <div class="error">Введите фамилию, допустиы кириллица, пробел и -</div>
            </div>
            <div class="email elem">
                <label for="email">Электронная почта</label>
                <input type="email" name="email" placeholder="Введите email">
                <div class="error">Введите почту в формате name@domain.com</div>
            </div>
            <div class="phone elem">
                <label for="phone">Номер телефона</label>
                <input type="tel" name="phone" placeholder="+7">
                <div class="error">Введите телефон правильно</div>
            </div>
            <div class="text elem">
                <label for="text">Интересующий вас вопрос или услуга</label>
                <textarea name="text" placeholder="Комментарий в свободной форме"></textarea>
            </div>
            <input type="file" class="files" multiple>
            <button @click="submitForm">Отправить</button>
        </form>
    </div>
</template>
<style lang='scss' scoped>
    .contact-form{
        margin-top: 56px;
        width: 720px;
        &__title{
            font-family: 'Roboto';
            font-size: 24px;
            font-weight: 500;
            line-height: 32px;
            color: #505050;
        }
        form{
            display: flex;
            flex-wrap: wrap;
            .elem{
                width: 344px;
                display: flex;
                flex-direction: column;
                margin-top: 32px;
                input, textarea{
                    margin-top: 10px;
                    height: 48px;
                    line-height: 24px;
                    color: #323232;
                    border: 1px solid #b4b4b4;
                    padding: 12px 20px;
                    font-family: 'Roboto';
                    font-size: 18px;
                    font-weight: 400;
                    line-height: 24px;
                }
                &:nth-child(even){
                    margin-left: 32px;
                }
            }
            .error{
                margin-top: 5px;
                font-family: 'Roboto';
                font-size: 12px;
                font-weight: 400;
                line-height: 14px;
                color: #b75106;
                display: none;
            }
            .error-container{
                input{
                    border-color: #b75106;
                }
                .error{
                    display: block;
                }
            }
            .text{
                width: 100%;
                textarea{
                    height: 152px;
                }
            }
            .files{
                margin-top: 30px;
                width: 100%;
            }
            button{
                margin-top: 74px;
                margin-left: auto;
                background-color: #b75106;
                padding: 0 27px;
                height: 48px;
                display: flex;
                align-items: center;
                justify-content: center;
                color: #fff;
                outline: none;
                font-family: 'Roboto';
                font-size: 16px;
                text-transform: uppercase;
                cursor: pointer;
                font-weight: 500;
                transition-duration: .3s;
                line-height: 24px;
                border: none;

                &:hover{
                    background-color: #EF7D05;
                }

                &:active{
                    background-color: #505050;
                    transition-duration: 0s;
                }

                &:disabled{
                    background-color: #f5f5f5;
                    color: #b4b4b4;
                }
            }
        }
    }
</style>

<script>

export default {
  name: 'ContactPage',
  methods: {
    checkForm () {
      var error = []
      const name = document.querySelector('.name input')
      const surname = document.querySelector('.surname input')
      const email = document.querySelector('.email input')
      const phone = document.querySelector('.phone input')
      if (name.value.length === 0) {
        error.push('name')
        document.querySelector('.name').classList.add('error-container')
      }
      if (surname.value.length === 0) {
        error.push('surname')
        document.querySelector('.surname').classList.add('error-container')
      }
      if (email.validity.typeMismatch) {
        error.push('email')
        document.querySelector('.email').classList.add('error-container')
      }
      if (phone.value.length === 0 || phone.value.length > 12) {
        error.push('phone')
        document.querySelector('.phone').classList.add('error-container')
      }
    },
    submitForm (e) {
      e.preventDefault()
      this.checkForm()
    }
  }
}
</script>
