<template>
    <div class="sub-menu">
        <slot></slot>
    </div>
</template>

<style lang="scss" scoped>
    .sub-menu{
        box-shadow: inset 0px 6px 14px rgba(0,0,0,0.15);
        border-bottom: 1px solid #c8c8c8;
        padding: 0 40px;
        display: flex;
        &__item{
            font-family: 'Roboto';
            padding: 10px 0;
            font-size: 18px;
            font-weight: 400;
            line-height: 24px;
            color: #505050;
            border-bottom: 4px solid transparent;
            transition-duration: .3s;
            &:not(:first-of-type){
                margin-left: 32px;
            }
            &:hover{
                color: #EF7D05;
            }
        }
        .router-link-active{
            border-bottom: 4px solid #B75106;
        }
    }
</style>

<script>
export default {
  name: 'SubMenu'
}
</script>
