<template>
    <header>
        <div class="top-header">
            <div class="top-header__items">
                <a href="#" class="top-header__items__item">Партнерам</a>
                <a href="#" class="top-header__items__item">Поставщикам</a>
                <a href="#" class="top-header__items__item">Документы и положения</a>
                <router-link class="top-header__items__item" to='/about/contact'>Контакты</router-link>
            </div>
        </div>
        <div class="main-header">
            <div class="main-header__menu">
                <router-link class="main-header__menu__logo" to="/">
                    <img src="@/assets/core/logotype.png" alt="logotype">
                </router-link>
                <div class="main-header__menu__items">
                    <router-link class="main-header__menu__items__item" exact to="/about">О компании</router-link>
                    <router-link class="main-header__menu__items__item" exact to="/about/contact">Контакты</router-link>
                </div>
            </div>
            <div class="union-call-and-burger">
                <div class="main-header__call-me">
                    <!--<Button text='Оставить заявку' url='#'/>-->
                    <a href="tel:+74852388040">+7 4852 38-80-40</a>
                </div>
                <div class="main-header__burger-icon" @click="changeBurger()">
                    <span class="main-header__burger-icon__topline"></span>
                    <span class="main-header__burger-icon__middleline"></span>
                    <span class="main-header__burger-icon__bottomline"></span>
                </div>
                <div class="main-header__burger-menu">
                    <router-link class="main-header__burger-menu__item" exact to="/about">О компании</router-link>
                    <router-link class="main-header__burger-menu__item" exact to="/about/contact">Контакты</router-link>
                </div>
            </div>
        </div>
    </header>
</template>

<style lang="scss" scoped>
    .top-header{
        display: none;
        overflow: hidden;
        height: 40px;
        width: 100%;
        background-color: #505050;
        padding: 0 40px;
        //display: flex;
        align-items: center;
        &__items{
            display: flex;
            align-items: center;
            &__item{
                font-family: 'Roboto';
                font-size: 14px;
                font-weight: 400;
                line-height: 16px;
                color: #C8C8C8;
                text-decoration: none;
                &:not(:first-of-type){
                    margin-left: 40px;
                }
            }
        }
    }
    .main-header{
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: 32px 40px;
        position: relative;
        box-shadow: 0px 4px 32px 0px rgba(0, 0, 0, 0.15);
        &__burger-icon,
        &__burger-menu{
            display: none;
        }
        &__menu{
            display: flex;
            &__logo{
                max-width: 100px;
                max-height: 25px;
            }
            &__items{
                display: flex;
                margin-left: 40px;
                align-items: center;
                &__item{
                    font-family: 'Roboto';
                    box-sizing: border-box;
                    font-size: 16px;
                    font-weight: 400;
                    line-height: 24px;
                    color: #505050;
                    text-transform: uppercase;
                    transition-duration: .3s;
                    &:not(:first-child){
                        margin-left: 40px;
                    }
                    &:hover{
                        color: #B75106;
                    }
                }
            }
        }
        .union-call-and-burger{
            display: flex;
            align-items: center;
        }
        &__call-me a{
            font-family: 'Roboto';
            font-size: 24px;
            font-weight: 700;
            line-height: 32px;
            color: #B75106;
        }
        @media screen and (max-width: 960px) {
            &__menu__items{
                display: none;
            }
            &__burger-icon{
                cursor: pointer;
                width: 24px;
                margin-left: 32px;
                height: 24px;
                display: flex;
                flex-direction: column;
                justify-content: center;
                &__topline, &__middleline, &__bottomline{
                    width: 24px;
                    display: block;
                    height: 4px;
                    background-color: #323232;
                    transition: 0.3s;
                }
                &__middleline{
                    margin: 4px 0;
                }
                &.active{
                    .main-header__burger-icon__topline{
                        transform: rotate(-45deg) translate(-5px, 7px);
                    }
                    .main-header__burger-icon__middleline{
                        opacity: 0;
                    }
                    .main-header__burger-icon__bottomline{
                        transform: rotate(45deg) translate(-5px, -6px);
                    }
                }
            }
            &__burger-menu.active{
                display: flex;
            }
            &__burger-menu{
                display: none;
                z-index: 1;
                position: absolute;
                top: 100%;
                right: 0;
                width: 320px;
                flex-direction: column;
                background-color: #fff;
                box-shadow: 0px 4px 32px 0px rgba(0, 0, 0, 0.15);
                padding: 32px 40px;
                &__item{
                    font-family: 'Roboto';
                    font-size: 16px;
                    font-weight: 400;
                    line-height: 24px;
                    color: #505050;
                    text-transform: uppercase;
                    padding: 0 40px;
                    transition-duration: .3s;
                    &:not(:first-of-type){
                        margin-top: 32px;
                    }
                    &:hover{
                        color: #B75106;
                    }
                }
            }

        }
        @media screen and (max-width: 960px){
            .main-header__call-me{
                display: none;
            }
        }
        .router-link-active{
            color: #B75106
        }
    }
</style>

<script>
import Button from '@/components/helpers/button/Button.vue'

export default {
  name: 'Header',
  components: {
    Button
  },
  methods: {
    changeBurger () {
      const icon = document.querySelector('.main-header__burger-icon')
      icon.classList.toggle('active')
      const menu = document.querySelector('.main-header__burger-menu')
      menu.classList.toggle('active')
    }
  }
}
</script>
