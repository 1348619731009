<template>
    <div class="main">
        <Header/>
        <BannerHome 
            title='НПО ТЛК' 
            desc='Разработка и производство серверных конструктивов и металлоконструкций' 
            textButton='Продукция'
            img='image12.png'
        />
        <div class="grid-block-home">
            <CardStandart img='image13.png' id="about-company" title='О компании' desc='НПО ТЛК — молодое но амбициознаное предприятие, созданое как независимая площадка по производству изделий из листового металла.'></CardStandart>
            <CardStandart img='image7.png' id="production" title='Производство' desc='Пройдите тур по нашему производству. Вы узнаете об этапах разработки и создания изделий, наших возможностях и оборудовании.'></CardStandart>
            <CardStandart img='image15.png' id="order" title='Под Заказ' desc='Хотите внести изменения в изделие или ищете площадку для реализации собственного проекта? Обратитесь к нам, чтобы воплотить вашу идею в металле.'></CardStandart>
            <CardStandart img='image6.png' id="career" title='Карьера' desc='Опытный профессионал или молодой специалист? Приходите к нам, найдем работу для каждого!'></CardStandart>
            <CardNews id="news"></CardNews>
        </div>
        <BannerHome
            :centerText='true'
            title='Работаем на будущее'
            desc='Мы не просто производим конструктивы, но предлагаем решения, выводящие отечественное серверное оборудование на новый уровень'
            img='image10.png'
        ></BannerHome>
        <div class="grid-block-benefit">
            <CardStandart img='image17.png' title='Наш Подход' desc='Использование современного оборудования и комплексной системы автоматизации позволяет нам внедрять самые передовые технологии и повышать производительность труда и уровень культуры производства, снижая себестоимость изделий, уменьшая процент внутреннего брака и значительно экономя используемые энергоресурсы'></CardStandart>
            <CardStandart img='image16.png' title='Территория Опережающего Развития ГАВРИЛОВ ЯМ' desc='Создание зоны опережающего развития будет способствовать диверсификации экономики города, снижению зависимости от градообразующего предприятия, созданию новых рабочих мест и привлечению инвестиций. Компания НПО ТЛК стала одним из пионеров, поспособствовав предоставлению городу статуса ТОР.'></CardStandart>
            <CardStandart img='image11.png' title='Социальная ответственность' desc='Мы рассматриваем компанию как комплексную систему для процветания которой необходдимо взаимовыгодное сотрудничество с партнерами, поставщиками, органами власти, трудовым коллективом и общественностью. 
Вкладываясь в обучение и развите сотрудников, мы не только повышаем культуру производства, но инвестируем в наш совместный успех, в общее будущее.'></CardStandart>
            <CardStandart img='image9.png' title='Об охране труда' desc='Безопасность труда относится к числу приоритетов компании. Мы создаем все условия для успешной работы, заботимся о людах и пристально следим за их безопасностью, ведь люди – наш самый ценный актив. 
Своевременное выявление проблем, разработка положений и инструктаж позволяют нам формировать культуру безопасного поведения, основанную на личной ответственности и вовлеченности сотрудников.'></CardStandart>
        </div>
        <DummyFooter/>
    </div>
</template>
<style lang="scss" scoped>
    .grid-block-home{
        display: grid;
        width: 100%;
        grid-template-columns: repeat(3, 1fr);
        grid-template-rows: repeat(2, minmax(336px, auto));
        #news{
            grid-column: 3/3;
            grid-row: 1/3;
        }
        @media screen and (max-width: 1440px) {
            grid-template-columns: repeat(2, 1fr);
            grid-template-rows: repeat(3, minmax(336px, auto));
            #news{
                grid-column: 1/3;
                grid-row: 3;
            }
        }
        @media screen and (max-width: 640px) {
            height: auto;
            grid-template-columns: 1fr;
            grid-template-rows: repeat(5, minmax(252px, auto));
            #news{
                grid-column: 1;
                grid-row: 5;
            }
        }
        @media screen and (max-width: 480px) {
            grid-template-rows: repeat(5, minmax(400px, auto));
        }
    }
    .grid-block-benefit{
        display: grid;
        width: 100%;
        grid-template-columns: repeat(4, 1fr);
        grid-template-rows: minmax(504px, auto);
        @media screen and (max-width: 1440px) {
            height: auto;
            grid-template-columns: repeat(2, 1fr);
            grid-template-rows: repeat(2, minmax(504px, auto));
        }
        @media screen and (max-width: 960px) {
            grid-template-rows: repeat(2, minmax(600px, auto));
        }
        @media screen and (max-width: 640px) {
            grid-template-columns: 1fr;
            grid-template-rows: repeat(4, minmax(370px, auto));
        }
        @media screen and (max-width:480px) {
            grid-template-rows: repeat(4, minmax(470px, auto));
        }
    }
</style>

<script>
import Header from '@/components/helpers/header/Header.vue'
import BannerHome from '@/components/helpers/banners/BannerHome.vue'
import CardStandart from '@/components/helpers/cards/CardStandart.vue'
import CardCarousel from '@/components/helpers/cards/CardCarousel.vue'
import CardNews from '@/components/helpers/cards/CardNews.vue'
import DummyFooter from '@/components/helpers/footer/DummyFooter.vue'
import Carousel from '@/components/helpers/carousel/Carousel.vue'

export default {
  name: 'HomePage',
  components: {
    Header,
    BannerHome,
    CardNews,
    CardStandart,
    Carousel,
    CardCarousel,
    DummyFooter
  },
}
</script>
